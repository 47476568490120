import {
  Box,
  Button,
  FormControl,
  IconButton,
  Input,
  Text,
} from "@suit-ui/react";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { MdOutlineVisibility } from "react-icons/md";
import { useState } from "react";
import {
  PasswordMinLength,
  PasswordRegexValidations,
  PasswordZodSchema,
} from "@/features/auth/utils/password";
import { CheckValidation } from "../components/CheckPasswordValidation";
import { useChangePassword } from "../useCases/useChangePassword";
import { useUser } from "../useCases/useUser";

const schema = z
  .object({
    currentPassword: z.string().min(1, { message: "field_required" }),
    newPassword: PasswordZodSchema,
    newPasswordConfirmation: z.string().min(1, { message: "field_required" }),
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: "passwords_dont_match",
    path: ["newPasswordConfirmation"],
  });

type ChangePasswordFormData = z.infer<typeof schema>;

export function ChangePasswordPage() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<ChangePasswordFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });
  const [toggleShowPasswords, setToggleShowPasswords] = useState<
    Record<keyof ChangePasswordFormData, boolean>
  >({
    currentPassword: false,
    newPassword: false,
    newPasswordConfirmation: false,
  });
  const {
    changePassword,
    isPending: isChangingPassword,
    isSuccess,
  } = useChangePassword();
  const { data: userData } = useUser();

  const getType = (name: keyof ChangePasswordFormData) =>
    toggleShowPasswords[name] ? "text" : "password";

  const toggleType = (name: keyof ChangePasswordFormData) => () => {
    setToggleShowPasswords((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const watchNewPassword = watch("newPassword");

  const isValidPassword = (
    validation: keyof typeof PasswordRegexValidations | "min_length",
  ) => {
    if (validation === "min_length")
      return z.string().min(PasswordMinLength).safeParse(watchNewPassword)
        .success;

    return z
      .string()
      .regex(PasswordRegexValidations[validation])
      .safeParse(watchNewPassword).success;
  };

  const onSubmit = async ({
    currentPassword,
    newPassword,
  }: ChangePasswordFormData) => {
    await changePassword({
      currentPassword,
      newPassword,
    });

    setTimeout(() => {
      window.location.href = "/";
    }, 3000);
  };

  return (
    <Layout className="p-11">
      <Text as="h2" className="font-bold text-xl text-center pb-2">
        {isSuccess
          ? t("change_password_page.success_title")
          : t("change_password_page.title")}
      </Text>
      <Text className="text-sm text-center">
        {isSuccess
          ? t("change_password_page.success_description")
          : t("change_password_page.description")}
      </Text>

      {!isSuccess && (
        <Box className="bg-info-100 text-info-700 p-4 text-center font-bold mt-5 text-sm rounded-sm">
          {userData?.user.email}
        </Box>
      )}

      {!isSuccess && (
        <Box
          as="form"
          className="mt-7 flex flex-col gap-5"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl required isInvalid={!!errors.currentPassword}>
            <FormControl.Label>
              {t("change_password_page.current_password")}
            </FormControl.Label>
            <Input.Group className="mb-3">
              <Input
                {...register("currentPassword")}
                autoComplete="off"
                type={getType("currentPassword")}
              />
              <Input.RightElement>
                <IconButton
                  size="sm"
                  variant="text"
                  color="neutral"
                  icon={<MdOutlineVisibility />}
                  onClick={toggleType("currentPassword")}
                />
              </Input.RightElement>
            </Input.Group>

            {!!errors.currentPassword && (
              <FormControl.ErrorMessage>
                {t(`change_password_page.${errors.currentPassword.message!}`)}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <FormControl required isInvalid={!!errors.newPassword}>
            <FormControl.Label>
              {t("change_password_page.new_password")}
            </FormControl.Label>
            <Input.Group className="mb-3">
              <Input
                {...register("newPassword")}
                autoComplete="off"
                type={getType("newPassword")}
              />
              <Input.RightElement>
                <IconButton
                  size="sm"
                  variant="text"
                  color="neutral"
                  icon={<MdOutlineVisibility />}
                  onClick={toggleType("newPassword")}
                />
              </Input.RightElement>
            </Input.Group>

            <Box className="grid grid-cols-2 gap-y-2 gap-x-7.5">
              <CheckValidation
                isValid={isValidPassword("min_length")}
                isInvalid={
                  !isValidPassword("min_length") && !!errors.newPassword
                }
              >
                {t("password_validation.min_length")}
              </CheckValidation>
              <CheckValidation
                isValid={isValidPassword("needs_uppercase")}
                isInvalid={
                  !isValidPassword("needs_uppercase") && !!errors.newPassword
                }
              >
                {t("password_validation.needs_uppercase")}
              </CheckValidation>
              <CheckValidation
                isValid={isValidPassword("needs_lowercase")}
                isInvalid={
                  !isValidPassword("needs_lowercase") && !!errors.newPassword
                }
              >
                {t("password_validation.needs_lowercase")}
              </CheckValidation>
              <CheckValidation
                isValid={isValidPassword("needs_number")}
                isInvalid={
                  !isValidPassword("needs_number") && !!errors.newPassword
                }
              >
                {t("password_validation.needs_number")}
              </CheckValidation>
              <CheckValidation
                isValid={isValidPassword("needs_special_character")}
                isInvalid={
                  !isValidPassword("needs_special_character") &&
                  !!errors.newPassword
                }
              >
                {t("password_validation.needs_special_character")}
              </CheckValidation>
            </Box>
          </FormControl>

          <FormControl required isInvalid={!!errors.newPasswordConfirmation}>
            <FormControl.Label>
              {t("change_password_page.confirm_password")}
            </FormControl.Label>
            <Input.Group>
              <Input
                {...register("newPasswordConfirmation")}
                autoComplete="off"
                type={getType("newPasswordConfirmation")}
              />
              <Input.RightElement>
                <IconButton
                  size="sm"
                  variant="text"
                  color="neutral"
                  icon={<MdOutlineVisibility />}
                  onClick={toggleType("newPasswordConfirmation")}
                />
              </Input.RightElement>
            </Input.Group>

            {!!errors.newPasswordConfirmation && (
              <FormControl.ErrorMessage>
                {t(
                  `change_password_page.${errors.newPasswordConfirmation
                    .message!}`,
                )}
              </FormControl.ErrorMessage>
            )}
          </FormControl>

          <Button
            className="w-fit self-center"
            type="submit"
            disabled={!isValid || isChangingPassword}
            loading={isChangingPassword}
          >
            {t("change_password_page.change_password")}
          </Button>
        </Box>
      )}
    </Layout>
  );
}
