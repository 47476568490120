import { createBrowserRouter } from "react-router-dom";
import { LoginPage } from "./features/auth/pages/LoginPage";
import { ForgotPasswordPage } from "./features/auth/pages/ForgotPasswordPage";
import { RecoveryPage } from "./features/auth/pages/RecoveryPage";
import { ChangePasswordPage } from "./features/auth/pages/ChangePasswordPage";
import { AuthProvider } from "./features/auth/providers/AuthProvider";
import { LogoutPage } from "./features/auth/pages/LogoutPage";

export const router = createBrowserRouter([
  {
    path: "/logout",
    element: <LogoutPage />,
  },
  {
    path: "/",
    element: <AuthProvider />,
    children: [
      {
        index: true,
        element: <LoginPage />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/recovery",
        element: <RecoveryPage />,
      },
      {
        path: "/change-password",
        element: <ChangePasswordPage />,
      },
    ],
  },
]);
