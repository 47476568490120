interface RedirectToAppProps {
  token: string;
  redirect: string;
}
export function redirectToApp({ token, redirect }: RedirectToAppProps) {
  const url = new URL(redirect);

  url.searchParams.append("sso_token", token);
  window.location.href = url.toString();
}
