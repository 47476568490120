import { Box, Button, Text, Image } from "@suit-ui/react";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { redirectToApp } from "../useCases/redirectToApp";
import { useGetApps } from "../useCases/useGetApps";
import { useNavigate } from "react-router-dom";

interface TenantAppsProps {
  token: string;
}

export function TenantApps({ token }: TenantAppsProps) {
  const { t } = useTranslation();
  const { data } = useGetApps();
  const navigate = useNavigate();

  return (
    <Layout className="p-11">
      <Text>{t("login_page.tenant_apps_title")}</Text>
      <Box className="mt-7">
        {data?.map((app) => (
          <Button
            key={app._id}
            size="lg"
            variant="text"
            className="w-full h-24 my-3 rounded-lg shadow-md"
            onClick={() =>
              redirectToApp({
                token,
                redirect: app.redirect_url,
              })
            }
          >
            <Image
              src={`/${app.code}.png`}
              alt={app.name}
              className="w-[250px]"
            />
          </Button>
        ))}
      </Box>

      <Button
        className="ml-auto flex mt-4"
        onClick={() => (navigate("/logout"), { replace: true })}
      >
        {t("ui.logout_button")}
      </Button>
    </Layout>
  );
}
