import { useTranslation } from "react-i18next";
import Layout from "./Layout";
import { Text } from "@suit-ui/react";
import { useExchangeToken } from "../useCases/useExchangeToken";
import { useEffect } from "react";
import { redirectToApp } from "../useCases/redirectToApp";

interface ExchangeTokenProps {
  authToken: string;
  redirect: string;
}

export function ExchangeToken({ authToken, redirect }: ExchangeTokenProps) {
  const { t } = useTranslation();
  const { mutateAsync } = useExchangeToken();

  useEffect(() => {
    const goToApp = async () => {
      if (window.history.replaceState) {
        const newUrl = new URL(window.location.href);
        newUrl.searchParams.delete("auth_token");
        window.history.replaceState({}, document.title, newUrl);
      }

      const { token } = await mutateAsync({ token: authToken });

      redirectToApp({ token, redirect });
    };

    if (authToken) goToApp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  return (
    <Layout className="p-11">
      <Text as="h2" className="font-bold text-xl">
        {t("login_page.redirecting")}
      </Text>
    </Layout>
  );
}
