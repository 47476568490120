import { ThemeProvider } from "@suit-ui/react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TenantProvider } from "./features/auth/providers/TenantProvider";

import "./utils/i18n/i18nInit";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnReconnect: false,
    },
  },
});

export const Providers = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <TenantProvider>
          <RouterProvider router={router} />
        </TenantProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
