import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation } from "@tanstack/react-query";

interface ForgotPasswordParams {
  app: string | null;
  email: string;
}

const forgotPassword = async ({ app, email }: ForgotPasswordParams) => {
  const response = await fetcher.post(`/recovery`, {
    app,
    email,
  });

  return response.data;
};

export const useForgotPassword = () => {
  const query = useMutation({
    mutationFn: (data: ForgotPasswordParams) => forgotPassword(data),
  });

  return {
    forgotPassword: query.mutate,
    isPending: query.isPending,
    isSuccess: query.isSuccess,
    isError: query.isError,
  };
};
