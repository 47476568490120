import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";

interface ValidateRecoveryTokenParams {
  token: string;
  email: string;
}

const validateRecoveryToken = async ({
  token,
  email,
}: ValidateRecoveryTokenParams) => {
  try {
    const params = new URLSearchParams({ reset_password_token: token, email });
    const response = await fetcher.get(
      `/recovery/validate_token?${params.toString()}`,
      {},
    );

    return response.data;
  } catch (error) {
    if ((error as AxiosError).response?.status === 401) {
      throw new Error("expired_token");
    }
    throw new Error("unexpected_error");
  }
};

export const useValidateRecoveryToken = () => {
  const query = useMutation({
    mutationFn: (data: ValidateRecoveryTokenParams) =>
      validateRecoveryToken(data),
  });

  return {
    validateRecoveryToken: query.mutateAsync,
    isPending: query.isPending,
    isSuccess: query.isSuccess,
    isError: query.isError,
    error: query.error,
  };
};
