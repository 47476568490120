import { fetcher } from "@/utils/fetchers/fetcher";
import { useQuery } from "@tanstack/react-query";
import { App } from "../types/App";

const getApps = async () => {
  const response = await fetcher.get<App[]>("/apps", {
    withCredentials: true,
  });

  return response.data;
};

const GET_APPS_KEY = "GET_APPS_KEY";

export function useGetApps() {
  return useQuery({
    queryFn: getApps,
    queryKey: [GET_APPS_KEY],
    refetchOnMount: false,
  });
}
