import { Box, Spinner } from "@suit-ui/react";
import { useUser } from "../useCases/useUser";
import { useEffect } from "react";
import { redirectToApp } from "../useCases/redirectToApp";
import { useQueryParams } from "@/utils/router/useQueryParams";
import { Outlet } from "react-router-dom";

export function AuthProvider() {
  const queryParams = useQueryParams();
  const app = queryParams.get("app");
  const user = useUser({
    enabled: true,
    app,
  });

  useEffect(() => {
    if (user.data && user.data.url) {
      redirectToApp({ token: user.data.token, redirect: user.data.url });
    }
  }, [user.data]);

  if (user.isLoading)
    return (
      <Box className="flex justify-center items-center w-full h-screen">
        <Spinner size="20" />
      </Box>
    );

  if (user.data && user.data.url) return null;

  return <Outlet />;
}
