import { Banner } from "@suit-ui/react";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useLogout } from "../useCases/useLogout";
import { useNavigate } from "react-router-dom";

export function LogoutPage() {
  const { t } = useTranslation();
  const { mutateAsync } = useLogout();
  const navigate = useNavigate();

  useEffect(() => {
    mutateAsync().finally(() => {
      navigate("/", { replace: true });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="p-11">
      <Banner status="warning">{t("logout_page.loging_out")}</Banner>
    </Layout>
  );
}
