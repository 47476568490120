import { Box } from "@suit-ui/react";

interface LayoutProps {
  children: React.ReactNode;
  className?: string;
}

export default function Layout({ children, className = "" }: LayoutProps) {
  return (
    <Box className="min-h-screen flex items-center flex-col pt-16 pb-9 min-w-full bg-cover bg-[url('https://d1ucytjcbrpj9v.cloudfront.net/static/media/ct-background.5db9976f3ac98e79bc40.png')]">
      <Box
        className="w-[270px] mb-7"
        as="img"
        src={new URL("/lemon-suite-logo.svg", import.meta.url).href}
      />
      <Box
        className={`bg-neutral-50 w-full rounded-lg max-w-[600px] p-11 ${className}`}
      >
        {children}
      </Box>
    </Box>
  );
}
