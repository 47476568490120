import { Text, Box, FormControl, Input, Button, Link } from "@suit-ui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useQueryParams } from "@/utils/router/useQueryParams";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useForgotPassword } from "../useCases/useForgotPassword";
import { useUser } from "../useCases/useUser";
import { useEffect } from "react";

const schema = z
  .object({
    email: z.string().trim().email({ message: "login_page.invalid_email" }),
  })
  .required({ email: true });

type AuthFormData = z.infer<typeof schema>;

export function ForgotPasswordPage() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AuthFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });
  const { forgotPassword, isPending, isSuccess, isError } = useForgotPassword();
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const { data } = useUser();

  const token = data?.token;
  const app = queryParams.get("app");

  const onSubmit = ({ email }: AuthFormData) => forgotPassword({ app, email });

  useEffect(() => {
    if (token) navigate("/", { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (token) {
    return null;
  }

  if (isSuccess || isError) {
    return (
      <Layout className="p-11 flex flex-col gap-6">
        <Text as="h2" className="font-bold text-xl text-center">
          {t("forgot_password_page.email_sent")}
        </Text>
        <Text className="text-sm">
          {t("forgot_password_page.email_sent_message")}
        </Text>

        <Button
          className="w-fit self-center"
          onClick={() =>
            navigate({
              pathname: "/",
              search: window.location.search,
            })
          }
        >
          {t("forgot_password_page.back_to_login")}
        </Button>
      </Layout>
    );
  }

  return (
    <Layout className="p-11">
      <Text as="h2" className="font-bold text-xl text-center pb-4">
        {t("forgot_password_page.title")}
      </Text>
      <Text className="text-sm">{t("forgot_password_page.description")}</Text>

      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        className="mt-7 flex flex-col gap-5"
      >
        <FormControl required isInvalid={!!errors.email}>
          <FormControl.Label>
            {t("forgot_password_page.email")}
          </FormControl.Label>
          <Input {...register("email")} />
          {!!errors.email && (
            <FormControl.ErrorMessage>
              {t(errors.email.message!)}
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <Box className="flex items-center justify-between">
          <Link
            as={RouterLink}
            to={{ pathname: "/", search: window.location.search }}
            disableVisitedStyle
          >
            {"< " + t("forgot_password_page.back_to_login")}
          </Link>

          <Button type="submit" disabled={!isValid} loading={isPending}>
            {t("forgot_password_page.send")}
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}
