import { API_URL } from "@/configs";
import axios from "axios";
import * as Sentry from "@sentry/browser";

const tenant = window.location.host.split(".")[0];

export const fetcher = axios.create({
  baseURL: API_URL.replace("{tenant}", tenant),
});

fetcher.interceptors.response.use(
  (response) => response,
  (error) => {
    const payload = error?.config?.data;
    if (payload) {
      Sentry.setExtra("request_payload", JSON.parse(payload));
      Sentry.captureException(error);
    }

    return Promise.reject(error);
  },
);
