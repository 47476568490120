import {
  Text,
  Box,
  FormControl,
  Input,
  Button,
  Banner,
  Link,
  Image,
} from "@suit-ui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useQueryParams } from "@/utils/router/useQueryParams";
import Layout from "../components/Layout";
import { useLogin } from "../useCases/useLogin";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { ExchangeToken } from "../components/ExchangeToken";
import { useUser } from "../useCases/useUser";
import { TenantApps } from "../components/TenantApps";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { tenantNameFromURL } from "../utils/tenant";

const schema = z
  .object({
    email: z.string().trim().email({ message: "login_page.invalid_email" }),
    password: z.string(),
  })
  .required({ email: true, password: true });

type AuthFormData = z.infer<typeof schema>;

export function LoginPage() {
  const { t } = useTranslation();
  const tenantName = tenantNameFromURL();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });

  const { data, error: userError } = useUser();
  const { mutate, isPending, isSuccess, isError } = useLogin();
  const [showInputControls, setShowInputControls] = useState<boolean>(true);

  const token = data?.token;
  const queryParams = useQueryParams();
  const app = queryParams.get("app");
  const redirect = queryParams.get("redirect");

  const authTokenParam = queryParams.get("auth_token");

  const onSubmit = ({ email, password }: AuthFormData) => {
    mutate({ email: email.trim(), password, app });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error = ((userError as AxiosError)?.response?.data as any)?.error;

    if (["tenant_not_found", "tenant_not_active"].includes(error)) {
      setShowInputControls(false);
      return;
    }

    setShowInputControls(true);
  }, [userError]);

  if (authTokenParam && redirect) {
    return <ExchangeToken authToken={authTokenParam} redirect={redirect} />;
  }

  if (token && !app) {
    return <TenantApps token={token} />;
  }

  return (
    <Layout className="p-11">
      {isError && (
        <Banner status="warning" className="mt-4">
          {t("login_page.credentials_error")}
        </Banner>
      )}

      {!showInputControls && (
        <Banner status="warning">
          {t(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            `login_page.${((userError as AxiosError).response?.data as any)
              ?.error}`,
            { tenantName },
          )}
        </Banner>
      )}

      {showInputControls && (
        <>
          <Box className="text-center">
            <Text as="h2" className="font-bold text-xl">
              {t("login_page.title")}
            </Text>
            <Text className="text-sm">{t("login_page.add_credentials")}</Text>
            {app && (
              <Box className="flex justify-center pt-6">
                <Image
                  src={`/${app}.png`}
                  fallbackSrc="image-not-found.svg"
                  alt={app}
                  className="w-[230px]"
                />
              </Box>
            )}
          </Box>

          <Box
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            className="mt-7 flex flex-col gap-5"
          >
            <FormControl required isInvalid={!!errors.email}>
              <FormControl.Label>{t("login_page.email")}</FormControl.Label>
              <Input {...register("email")} />
              {!!errors.email && (
                <FormControl.ErrorMessage>
                  {t(errors.email.message!)}
                </FormControl.ErrorMessage>
              )}
            </FormControl>

            <FormControl required isInvalid={!!errors.password}>
              <FormControl.Label>{t("login_page.password")}</FormControl.Label>
              <Input type="password" {...register("password")} />
              {!!errors.password && (
                <FormControl.ErrorMessage>
                  {errors.password.message!}
                </FormControl.ErrorMessage>
              )}
            </FormControl>

            <Link
              as={RouterLink}
              to={{
                pathname: "/forgot-password",
                search: window.location.search,
              }}
              className="self-end"
              disableVisitedStyle
            >
              {t("login_page.forgot_password")}
            </Link>

            <Box className="flex items-center justify-between">
              <Text className="text-sm">
                * {t("login_page.required_fields")}
              </Text>

              <Button type="submit" loading={isPending || isSuccess}>
                {t("login_page.login")}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Layout>
  );
}
