import { Box, Icon } from "@suit-ui/react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import clsx from "clsx";

interface CheckValidationsProps {
  isValid?: boolean;
  isInvalid?: boolean;
  children?: React.ReactNode;
}

export const CheckValidation: React.FC<CheckValidationsProps> = ({
  isInvalid,
  isValid,
  children,
}) => {
  return (
    <Box
      className="text-neutral-900 flex gap-1 items-center text-sm"
      apply={clsx(
        isValid && "text-success-600",
        isInvalid && "text-danger-600",
      )}
    >
      <Icon as={isInvalid ? MdCancel : MdCheckCircle} size="3.5" />
      {children}
    </Box>
  );
};
