import { Banner } from "@suit-ui/react";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";

interface TenantProviderProps {
  children: React.ReactNode;
}

export function TenantProvider({ children }: TenantProviderProps) {
  const { t } = useTranslation();

  const host = window.location.host;
  const hostParts = host.split(".");
  const tenant = host.length > 1 ? hostParts[0] : null;

  if (!tenant)
    return (
      <Layout className="p-11">
        <Banner status="danger">{t("ui.tenant_not_found")}</Banner>
      </Layout>
    );

  return children;
}
