import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const logout = async () => {
  const response = await fetcher.delete("/users/sign_out");

  return response.data;
};

export function useLogout() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: logout,
    onSuccess: () => {
      queryClient.clear();
    },
  });
}
