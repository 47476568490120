import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation } from "@tanstack/react-query";
import { redirectToApp } from "./redirectToApp";
import { User } from "../types/User";
import { WITH_CREDENTIALS } from "@/configs";

interface LoginProps {
  email: string;
  password: string;
  app?: string | null;
}

const login = async ({ email, password, app }: LoginProps) => {
  const response = await fetcher.post<User>(
    "/users/sign_in",
    {
      user: { email, password },
      app,
    },
    {
      withCredentials: WITH_CREDENTIALS,
    },
  );

  return response.data;
};

export function useLogin() {
  return useMutation({
    mutationFn: login,
    onSuccess: ({ token, url: redirect }) => {
      if (!redirect) window.location.reload();
      else redirectToApp({ token, redirect });
    },
  });
}
