import { fetcher } from "@/utils/fetchers/fetcher";
import { User } from "../types/User";
import { useQuery } from "@tanstack/react-query";

const getUser = async (app?: string | null) => {
  const response = await fetcher.get<User>("/me", {
    params: { app },
    withCredentials: true,
  });

  return response.data;
};

const GET_CURRENT_USER_KEY = "GET_CURRENT_USER_KEY";

interface UseUserProps {
  enabled?: boolean;
  app?: string | null;
}

export function useUser({ enabled = false, app }: UseUserProps = {}) {
  return useQuery({
    queryFn: () => getUser(app),
    queryKey: [GET_CURRENT_USER_KEY, app],
    refetchOnMount: false,
    enabled,
  });
}
