import { fetcher } from "@/utils/fetchers/fetcher";
import { useMutation } from "@tanstack/react-query";

interface ExchangeTokenProps {
  token: string;
}

const exchangeToken = async ({ token }: ExchangeTokenProps) => {
  const response = await fetcher.post<{ token: string }>(
    "/exchange",
    {},
    {
      headers: {
        token: token,
      },
    },
  );

  return response.data;
};

export function useExchangeToken() {
  return useMutation({
    mutationFn: exchangeToken,
  });
}
