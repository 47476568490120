import {
  Text,
  Box,
  FormControl,
  Input,
  Button,
  Spinner,
  IconButton,
} from "@suit-ui/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useQueryParams } from "@/utils/router/useQueryParams";
import Layout from "../components/Layout";
import { useTranslation } from "react-i18next";
import { useValidateRecoveryToken } from "../useCases/useValidateRecoveryToken";
import { useRecoveryPassword } from "../useCases/useRecoveryPassword";
import { useNavigate } from "react-router-dom";
import { MdOutlineVisibility } from "react-icons/md";
import {
  PasswordMinLength,
  PasswordRegexValidations,
  PasswordZodSchema,
} from "../utils/password";
import { CheckValidation } from "../components/CheckPasswordValidation";
import { useUser } from "../useCases/useUser";

const schema = z
  .object({
    password: PasswordZodSchema,
    passwordConfirmation: z.string().min(1, { message: "field_required" }),
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "passwords_dont_match",
    path: ["passwordConfirmation"],
  });

type RecoveryFormData = z.infer<typeof schema>;

export function RecoveryPage() {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<RecoveryFormData>({
    resolver: zodResolver(schema),
    mode: "onBlur",
  });
  const navigate = useNavigate();
  const {
    validateRecoveryToken,
    isPending: isValidatingToken,
    isError: isInvalidToken,
  } = useValidateRecoveryToken();
  const {
    recoveryPassword,
    isPending: isChangingPassword,
    isSuccess,
  } = useRecoveryPassword();
  const [toggleShowPasswords, setToggleShowPasswords] = useState<
    Record<keyof RecoveryFormData, boolean>
  >({
    password: false,
    passwordConfirmation: false,
  });
  const queryParams = useQueryParams();
  const user = useUser();

  const recoveryToken = queryParams.get("token");
  const email = queryParams.get("email");
  const app = queryParams.get("app");

  useEffect(() => {
    if (!recoveryToken) return;
    validateRecoveryToken({ token: recoveryToken, email: email as string });
  }, [email, recoveryToken, validateRecoveryToken]);

  const onSubmit = async ({ password }: RecoveryFormData) => {
    if (!recoveryToken) return;

    await recoveryPassword({
      password,
      token: recoveryToken,
      email: email as string,
    });
  };

  const getType = (name: keyof RecoveryFormData) =>
    toggleShowPasswords[name] ? "text" : "password";

  const toggleType = (name: keyof RecoveryFormData) => () => {
    setToggleShowPasswords((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const watchNewPassword = watch("password");

  const isValidPassword = (
    validation: keyof typeof PasswordRegexValidations | "min_length",
  ) => {
    if (validation === "min_length")
      return z.string().min(PasswordMinLength).safeParse(watchNewPassword)
        .success;

    return z
      .string()
      .regex(PasswordRegexValidations[validation])
      .safeParse(watchNewPassword).success;
  };

  if (isValidatingToken || user.isLoading)
    return (
      <Layout className="p-11 flex justify-center">
        <Spinner />
      </Layout>
    );

  if (!email || !recoveryToken || isInvalidToken) {
    return (
      <Layout className="p-11 space-y-6">
        <Box className="flex flex-col gap-4 items-center">
          <Text
            className="text-md text-center"
            style={{
              textWrap: "pretty",
            }}
          >
            {t("recovery_page.invalid_token_message")}
          </Text>

          <Button
            onClick={() =>
              navigate(`/forgot-password${app ? `/?app=${app}` : ""}`)
            }
          >
            {t("recovery_page.get_new_link")}
          </Button>
        </Box>
      </Layout>
    );
  }

  if (
    !user.isLoading &&
    user.data?.user.email &&
    user.data?.user.email !== email
  ) {
    return (
      <Layout className="p-11">
        <Box className="flex flex-col items-center">
          <Text as="h2" className="font-bold text-xl">
            {t("recovery_page.session_already_started_title")}
          </Text>

          <Text className="text-sm pt-2">
            {t("recovery_page.session_already_started")}
          </Text>

          <Button
            className="w-fit self-center mt-7"
            onClick={() => navigate("/logout")}
          >
            {t("ui.logout_button")}
          </Button>
        </Box>
      </Layout>
    );
  }

  if (isSuccess) {
    return (
      <Layout className="p-11">
        <Box className="flex flex-col items-center">
          <Text as="h2" className="font-bold text-xl">
            {t("recovery_page.password_changed")}
          </Text>
          <Text className="text-sm pt-2">
            {t("recovery_page.password_changed_message")}
          </Text>

          <Button
            className="w-fit self-center mt-7"
            onClick={() => {
              window.location.href = app ? `/?app=${app}` : "/";
            }}
          >
            {t("recovery_page.back_to_login")}
          </Button>
        </Box>
      </Layout>
    );
  }

  return (
    <Layout className="p-11">
      <Text as="h2" className="font-bold text-xl text-center">
        {t("recovery_page.title")}
      </Text>
      <Text className="text-sm text-center pt-2">
        {t("recovery_page.description")}
      </Text>

      <Box className="bg-info-100 text-info-700 p-4 text-center font-bold mt-5 text-sm rounded-sm break-all">
        {email}
      </Box>

      <Box
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        className="mt-7 flex flex-col gap-5"
      >
        <FormControl required isInvalid={!!errors.password}>
          <FormControl.Label>{t("recovery_page.password")}</FormControl.Label>
          <Input.Group className="mb-3">
            <Input
              {...register("password")}
              autoComplete="off"
              type={getType("password")}
            />
            <Input.RightElement>
              <IconButton
                size="sm"
                variant="text"
                color="neutral"
                icon={<MdOutlineVisibility />}
                onClick={toggleType("password")}
              />
            </Input.RightElement>
          </Input.Group>

          <Box className="grid grid-cols-2 gap-y-2 gap-x-7.5">
            <CheckValidation
              isValid={isValidPassword("min_length")}
              isInvalid={!isValidPassword("min_length") && !!errors.password}
            >
              {t("password_validation.min_length")}
            </CheckValidation>
            <CheckValidation
              isValid={isValidPassword("needs_uppercase")}
              isInvalid={
                !isValidPassword("needs_uppercase") && !!errors.password
              }
            >
              {t("password_validation.needs_uppercase")}
            </CheckValidation>
            <CheckValidation
              isValid={isValidPassword("needs_lowercase")}
              isInvalid={
                !isValidPassword("needs_lowercase") && !!errors.password
              }
            >
              {t("password_validation.needs_lowercase")}
            </CheckValidation>
            <CheckValidation
              isValid={isValidPassword("needs_number")}
              isInvalid={!isValidPassword("needs_number") && !!errors.password}
            >
              {t("password_validation.needs_number")}
            </CheckValidation>
            <CheckValidation
              isValid={isValidPassword("needs_special_character")}
              isInvalid={
                !isValidPassword("needs_special_character") && !!errors.password
              }
            >
              {t("password_validation.needs_special_character")}
            </CheckValidation>
          </Box>
        </FormControl>

        <FormControl required isInvalid={!!errors.passwordConfirmation}>
          <FormControl.Label>
            {t("recovery_page.confirm_password")}
          </FormControl.Label>
          <Input.Group>
            <Input
              {...register("passwordConfirmation")}
              autoComplete="off"
              type={getType("passwordConfirmation")}
            />
            <Input.RightElement>
              <IconButton
                size="sm"
                variant="text"
                color="neutral"
                icon={<MdOutlineVisibility />}
                onClick={toggleType("passwordConfirmation")}
              />
            </Input.RightElement>
          </Input.Group>

          {!!errors.passwordConfirmation && (
            <FormControl.ErrorMessage>
              {t(`recovery_page.${errors.passwordConfirmation.message!}`)}
            </FormControl.ErrorMessage>
          )}
        </FormControl>

        <Button
          className="w-fit self-center"
          type="submit"
          disabled={!isValid || isChangingPassword}
          loading={isChangingPassword}
        >
          {t("recovery_page.change_password")}
        </Button>
      </Box>
    </Layout>
  );
}
