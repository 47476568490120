import { z } from "zod";

export const PasswordRegexValidations = {
  needs_number: /\d/,
  needs_lowercase: /[a-z\u00DF-\u00F6\u00F8-\u00FF]/, // any Unicode lowercase letter
  needs_uppercase: /[A-Z\u00C0-\u00D6\u00D8-\u00DE]/, // any Unicode uppercase letter
  needs_special_character:
    /[\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\u00A1-\u00AC\u00AE-\u00FF]/, // any non-alphanumeric character
};

export const PasswordMinLength = 8;

export const PasswordZodSchema = z
  .string()
  .min(PasswordMinLength, { message: "min_length" })
  .regex(PasswordRegexValidations.needs_number, { message: "needs_number" })
  .regex(PasswordRegexValidations.needs_lowercase, {
    message: "needs_lowercase",
  })
  .regex(PasswordRegexValidations.needs_uppercase, {
    message: "needs_uppercase",
  })
  .regex(PasswordRegexValidations.needs_special_character, {
    message: "needs_special_character",
  });
