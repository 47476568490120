export const API_URL = import.meta.env.VITE_SSO_API_URL ?? "";

export const TBX_URL = import.meta.env.VITE_TBX_URL ?? "";
export const CTLITE_URL = import.meta.env.VITE_CTLITE_URL ?? "";
export const DMS_URL = import.meta.env.VITE_DMS_URL ?? "";

export const NODE_ENV = process.env.NODE_ENV;

export const WITH_CREDENTIALS =
  import.meta.env.VITE_WITH_CREDENTIALS === "true";
