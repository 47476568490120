import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface ChangePasswordParams {
  currentPassword: string;
  newPassword: string;
}

const changePassword = async ({
  currentPassword,
  newPassword,
}: ChangePasswordParams) => {
  const response = await fetcher.patch(
    `/change_password`,
    {
      current_password: currentPassword,
      new_password: newPassword,
    },
    {
      withCredentials: true,
    },
  );

  return response.data;
};

export const useChangePassword = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const query = useMutation({
    mutationFn: (data: ChangePasswordParams) => changePassword(data),
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
    onSuccess: () => {
      toast.add({
        content: t("change_password_page.password_changed"),
        status: "success",
        duration: 3000,
      });
    },
  });

  return {
    changePassword: query.mutateAsync,
    isPending: query.isPending,
    isSuccess: query.isSuccess,
  };
};
