import { fetcher } from "@/utils/fetchers/fetcher";
import { useToast } from "@suit-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface RecoveryPasswordParams {
  token: string;
  password: string;
  email: string;
}

const recoveryPassword = async ({
  token,
  password,
  email,
}: RecoveryPasswordParams) => {
  const params = new URLSearchParams({ reset_password_token: token, email });
  const response = await fetcher.patch(`/recovery/set?${params.toString()}`, {
    password,
  });

  return response.data;
};

export const useRecoveryPassword = () => {
  const toast = useToast();
  const { t } = useTranslation();

  const query = useMutation({
    mutationFn: (data: RecoveryPasswordParams) => recoveryPassword(data),
    onError: () => {
      toast.add({
        content: t("ui.unexpected_error"),
        status: "danger",
      });
    },
  });

  return {
    recoveryPassword: query.mutateAsync,
    isPending: query.isPending,
    isSuccess: query.isSuccess,
  };
};
