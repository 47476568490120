import { NODE_ENV } from "@/configs";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://021d79cbae32bab55a12b72b480ca829@o212660.ingest.us.sentry.io/4507306467131392",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: NODE_ENV,
});

